import React from 'react';

const Button = (props) => {
    const handleClick = () => {
        props.clickHandler(props.name);
    };

    return (
        <div className="button">
            <button onClick={handleClick} className={props.class}>{props.name}</button>
        </div>
    )
}

export default Button;