import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

class ButtonPanel extends Component {
    static propTypes = {
        clickHandler: PropTypes.func
    };

    handleClick = buttonName => {
        this.props.clickHandler(buttonName);
    };

    clickVerify = () => {
        this.props.clickVerify();
    }

    render() {
        return (
            <div className="buttton-panel">
                <div className="butttons-row">
                    <Button name="1" clickHandler={this.handleClick} />
                    <Button name="2" clickHandler={this.handleClick} />
                    <Button name="3" clickHandler={this.handleClick} />
                </div>
                <div className="butttons-row">
                    <Button name="4" clickHandler={this.handleClick} />
                    <Button name="5" clickHandler={this.handleClick} />
                    <Button name="6" clickHandler={this.handleClick} />
                </div>
                
                <div className="butttons-row">
                    <Button name="7" clickHandler={this.handleClick} />
                    <Button name="8" clickHandler={this.handleClick} />
                    <Button name="9" clickHandler={this.handleClick} />
                </div>
                <div className="butttons-row">
                    <Button name="Clear" clickHandler={this.handleClick} class="dark" />
                    <Button name="0" clickHandler={this.handleClick} />
                    <Button name="Check" clickHandler={this.clickVerify} class="orange" />
                </div>
            </div>
        )
    }
}

export default ButtonPanel;