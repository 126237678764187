import React, { Component } from 'react';

class DisplayPanel extends Component {
    render() {
        // console.log(this.props);
        return (
            <div className="dodging-qa">
                <div className="dodging-question">
                    {this.props.dodging.first} x {this.props.dodging.second}
                </div>
                <div className="dodging-equalto">=</div>
                <div className="dodging-answer">
                    {this.props.value}
                </div>
            </div>
        )
    }
}

export default DisplayPanel;