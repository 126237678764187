import React from 'react';
import ReactDOM from 'react-dom';
import Dodging from './components/dodging';

import './index.scss';

const App = () => {
    return (
        <div>
            <Dodging/>
        </div>
    )
}

ReactDOM.render(<App />, document.getElementById('root'));