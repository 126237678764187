import isNumber from "./isNumber";

/**
 * Given a button name and a calculator data object, return an updated
 * calculator data object.
 *
 * Calculator data object contains:
 *   total:String      the running total
 *   next:String       the next number to be operated on with the total
 *   operation:String  +, -, etc.
 */
export default function calculate(obj, buttonName) {
  if (buttonName === "Clear") {
    return {
      total: null,
      next: null
    };
  }

  if (isNumber(buttonName)) {
    if (buttonName === "0" && obj.next === "0") {
      return {};
    }
    // If there is no operation, update next and clear the value
    if (obj.next) {
      let next = obj.next === "0" ? buttonName : obj.next + buttonName;
      if(parseInt(next) > 1000) {
        next = obj.next;
      }
      return {
        next
      };
    }
    return {
      next: buttonName
    };
  }

  // save the operation and shift 'next' into 'total'
  return {
    next: null
  };
}
