import React, { Component } from 'react';
import ButtonPanel from './ButtonPanel/ButtonPanel';
import DisplayPanel from './DisplayPanel/DisplayPanel';
import calculate from '../logic/calculate';
import isNumber from '../logic/isNumber';
// import TimeCounter from '../components/TimeCounter/time_counter';
// import Timer from '../components/Timer/timer';

// import `cookie` from 'react-cookies';

class Dodging extends Component {
  state = {
    dodging: {
      first: 0,
      second: 0
    },
    min: 2,
    max: 16,
    newmin: 2,
    newmax: 16,
    correct: 0,
    count: 0,
    next : null,
    dataset: [],
    start: Date.now(),
    // settings: cookie.load('dodgingSettings'),
    value: 2,
    showSettings: false,
    showHistory: false,
    isLoading: false,
    showModal: false,
    answer: ''
  }

  resetState = () => {
    console.log(this.state);
    this.setState({
      dodging: this.generateDodging(),
      correct: 0,
      count: 0,
      next : null,
      dataset: [],
      start: Date.now(),
      showSettings: false,
      showHistory: false,
      isLoading: false,
      value: 2
    });
  }
  getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
  }

  handleClick = (buttonName) => {
    this.setState(calculate(this.state, buttonName));
  }

  generateDodging = () => {
    let first = this.getRandomIntInclusive(this.state.min, this.state.max);
    let second = this.getRandomIntInclusive(2, 9);
    return {first, second}
  }

  componentDidMount() {
    this.setState({
      dodging: this.generateDodging()
    });
  }

  verifyAndGenerate = () => {
    if(this.state.isLoading) return false;

    this.setState({
      isLoading: true,
      showModal: true
    });
    let state = this.state;
    let count = state.count + 1;

    let answer = parseInt(state.dodging.first * state.dodging.second);
    let inputValue = parseInt(state.next) || 0;

    let correct = state.correct;

    let data = {
      first: state.dodging.first,
      second: state.dodging.second,
    }
    let dataset = [...this.state.dataset, data];
    let msg = ['Correct', 'Good', 'Keep it up', 'Perfect', 'Awesome', 'Hi Five!', 'Good Shot', 'Excellent']
    if(answer === inputValue) {
      this.setState({answer: 'correct'})
      correct = state.correct + 1;
      data.correct = true;
      data.note = msg[this.getRandomIntInclusive(0,7)];
      data.answer = inputValue;
    } else {
      this.setState({answer: 'incorrect'})
      data.correct =  false;
      data.note = 'Correct answer is '+ answer;
      data.answer = inputValue
    }

    setTimeout(() => {
      this.setState({
        dodging: this.generateDodging(),
        newmin: this.state.min,
        newmax: this.state.max,
        correct,
        count,
        dataset,
        next : null,
        isLoading: false,
        showModal: false,
        answer: ''
      })
    }, 1000);      
  }


  renderData = () => {
    let values = this.state.dataset;
    return (
      values.map((item, i) => (
        <div className={"item " + (item.correct ? 'correct' : 'incorrect')} key={i}>
          <span className="first">{item.first}</span>
          <span className="operator">x</span>
          <span className="second">{item.second}</span>
          <span className="equalto">=</span>
          <span className="answer">{item.answer}</span>
          <span className="note">{item.note}</span>
          <div className="clearfix"></div>
        </div>
      )).reverse()
    )
  }



    // getTimeSpent = () => {
    //     return this.state.start
    // }

    handleReset = () => {
      this.setState({
        min: this.state.newmin,
        max: this.state.newmax
      });
      setTimeout(() => {
        this.resetState();
      }, 100);      
    }

    applySettings = () => {
      console.log('Apply Settings');
    }

    handleInputChange = (event) => {
      const name = event.target.name;
      let value = parseInt(event.target.value);
      this.setState({
        [name]: (isNumber(value)) ? value : 0
      });
    }
    handleInputMaxBlur = (event) => {
      if(parseInt(this.state.newmin) > parseInt(this.state.newmax)) {
        this.setState({
          newmax: (isNumber(this.state.newmin)) ? this.state.newmin : 0
        });
      }
    }

    handleToggle = name => {
      this.setState({
        [name]: !this.state[name]
      })
    }

    // startTimer() {
    //     this.setState({
    //       isOn: true,
    //       time: this.state.time,
    //       start: Date.now() - this.state.time
    //     })
    //     this.timer = setInterval(() => this.setState({
    //       time: Date.now() - this.state.start
    //     }), 1000);
    //   }
    

    render() {
        return (
            <div className="container">
                <div className="dodging-header">
                    <h1>Dodging App</h1>
                    <div className="dodging-score">Score : {this.state.correct} / {this.state.count}</div>
                </div>
                <div className="dodging">
                    <div className={`modal ${this.state.answer} ${this.state.showModal ? 'show' : 'hide'}`}></div>
                    <DisplayPanel value={this.state.next || "0"} dodging={this.state.dodging}/>
                    <ButtonPanel clickHandler={this.handleClick} clickVerify={this.verifyAndGenerate}/>
                </div>



                <div className={`toggle-wrapper dodging-settings ${this.state.showSettings ? 'expanded' : ''}`}>
                  <button onClick={() => this.handleToggle('showSettings')} className="toggle-btn">Settings</button>
                  <div className="toggle-content settings">
                    <p>Enter the range of tables</p>
                    <div className="input-wrapper">
                        <label>Min</label>
                        <input
                            type="text"
                            name="newmin"
                            maxLength="2"
                            value={this.state.newmin}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputMaxBlur}
                        />
                    </div>
                    <div className="input-wrapper">
                        <label>Max</label>
                        <input
                            type="text"
                            name="newmax"
                            maxLength="2"
                            value={this.state.newmax}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputMaxBlur}
                        />
                    </div>
                    <div className="input-wrapper input-wrapper__right">
                      <button className="btn-reset" onClick={this.handleReset}>Reset</button>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>


                <div className={`toggle-wrapper dodging-history ${this.state.showHistory ? 'expanded' : ''}`}>
                  <button onClick={() => this.handleToggle('showHistory')} className="toggle-btn">Your Answers</button>
                  <div className="toggle-content history-data">
                    {
                    this.state.dataset.length ? 
                      this.renderData()
                    : 
                      <p>You didn't answered any dodging yet.</p>
                    }
                  </div>
                </div>

                <div className="dodging-footer">
                  Created by <strong>Kapil Jain</strong>
                </div>
            </div>
        )
    }
}

export default Dodging;